.floating-ui-elements {
  .floating-bttn {
    width: 150px;
    max-width: 100vw;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 19px;
    margin-bottom: 12px;
    border-radius: 40px;
    cursor: pointer;
    z-index: 1000;
    position: fixed;
    bottom: 10px;
    right: 25px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
    .icon {
      width: 30px;
      color: var(--secondary-color);
    }
    .bttn-text {
      margin-left: 8px;
    }
  }

  .floating-title {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    text-align: center;
    position: fixed;
    z-index: 0;
    font-size: 13px;
    font-family: var(--header-font);
    color: var(--floating-title-text-color);
    background-color: transparent;
    opacity: 1;
    transition: all 1s ease-in;
    height: 47px;
    width: 100%;
    backdrop-filter: blur(12px);
    &.transparent {
      opacity: 0 !important;
    }
  }
}
