.place-details-drawer {
  position: absolute;
  top: 0;
  min-height: 100vh;
  max-width: 414px;
  width: 100vw;
  z-index: 20;
  transition: all 0.4s ease;
  background-color: var(--place-details-background);
  color: var(--place-details-text-color);
  &.closed {
    top: 100vh;
  }

  h1.place-name {
    font-family: var(--main-font);
    margin-top: 22px;
    margin-bottom: 8px;
    margin-right: 55px;
    color: var(--place-details-text-color);
  }
  p.place-address {
    max-width: 350px;
    color: var(--place-details-text-color);
  }

  .pin-container {
    position: absolute;
    right: 22px;
    top: 9px;
  }

  .tags-container {
    display: flex;
    margin-top:16px;
    .tag {
      margin:0;
      margin-right:12px;
      background-color: var(--place-details-background-action-bttn);
      padding:10px 20px;
      border-radius: 20px;
      border: 1px solid rgba(0,0,0,0.1);
      p {
        font-size: 14px;
        margin:0;
        color: var(--place-details-text-color);
      }
    }
  }
}
.close-bttn {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.close-bttn > svg {
  background-color: #404040;
  border-radius: 50%;
}

.place-details-drawer {
  .action-bttns-container {
    background-color: var(--place-details-background);
    .action-bttn {
      background-color: var(--place-details-background-action-bttn);
    }

    .action-bttn-text {
      margin-top: 8px !important;
      color: var(--place-details-text-color) !important;
    }
    img.google-maps-logo {
      height: 30px;
    }
    .yelp-logo-container {
      width: 100%;
      height: 100%;
      height: 30px !important;
    }
  }
}
// on desktop make action buttons animate on hover
.desktop .place-details-drawer {
  .action-bttn:hover {
    box-shadow: 0 0 14px rgba(0,0,0,0.1);
    p {
      font-weight: bold;
    }
  }
}
