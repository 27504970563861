$creamii: #F9F5EB;
$grasii: #00D890;
$skii: #30B4FF;
$hotii: #FF6F62;
$piink: #FACFDF;
$sunii: #F2D70B;
$viiolet: #8183F9;
$viiolet80: #2E2F5B;
$viiolet20: #EDEEF9;
$white: #FFFFFF;
$smokii: #1A1A1A;
$smokii-light: #373737;

$goldii: #F9DDC5
