.export-container {
  min-height: 100vh;
  background-color: $creamii;

  .success-container-too-small-device {
    display: none;
  }

  .url-to-export {
    opacity: 0.6;
    color: blue;
    cursor: pointer;
  }
}

.export-inner-container {
  max-width: 1280px;
  margin: 0 auto;
  min-height: 100vh;
}

.navigation-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.request-my-data-bttn {
  background-color: #FF635A;
  color: white;
  font-weight: bold;
  border-radius: 12px;
  cursor: pointer;
  font-family: recoleta;
  font-size: 1.8rem;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 14px 45px;
}

.logo-container,
.logo-container img {
  height: 40px;
}

.profile-container {
  display: flex;
  align-items: center;

  .left-side {
    padding-right: 10px;

    p.name {
      color: rgba(0, 0, 0, 0.8);
    }

    p.email {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .right-side,
  .right-side img {
    width: 45px;
    height: 45px;
    border-radius: 50px;
  }
}

.main-content-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;

  h1.title {
    font-size: 32px;
    font-weight: 100;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 3.6rem;
    font-size: 40px;
  }

  h1.title,
  p.subtitle {
    padding-left: 20px;
    padding-right: 20px;
  }

  p.subtitle {
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    line-height: 40px;
    font-size: 28px;
  }

  .info-alert {
    background: #FAF6E1;
    padding: 30px 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 20px;
    margin-right: 20px;

    &.waiting-too-long {
      margin-top: 40px;
      margin-bottom: -20px;
    }

    &.data-deletion-alert {
      background-color: #FBE7DD;
      margin-top: 28px;
    }
  }

  .info-alert:last-of-type {
    margin-bottom: 80px;
  }

  .status-box-container-outer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .status-box-container {
    width: 89%;
    display: grid;
    grid-template-columns: 40% 60%;
    background: #F1F2EC;
    padding: 30px 60px;
    border: 1.5px solid rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    margin-top: 20px;

    .name,
    .value {
      padding-bottom: 8px;
      padding-top: 8px;
    }

    .name {
      color: #323E4A;
      font-size: 20px;
    }

    .value {
      color: #9E9E9E;
      font-size: 18px;
    }


    .status-indicator-container {
      display: flex;
      align-items: center;

      .outer-circle {
        margin-right: 6px;
        margin-top: 2px;
        height: 16px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }

      .inner-circle {
        height: 8px;
        width: 8px;
        border-radius: 50%;
      }

      p {
        color: #1D794D;
      }
    }

    .status-indicator-container.green {
      .outer-circle {
        background-color: #9CC2AC;
      }

      .inner-circle {
        background-color: #1D794D;
      }
    }
  }

  .file-icon-container {
    svg.file-icon {
      margin-right: 18px;
    }

    .download-icon-container {
      padding: 10px;
      cursor: pointer;
    }

    position: relative;
    display: flex;
    padding: 10px;
    border-radius: 12px;
    background: #FDFBF7;
    height: 66px;
    margin-bottom: 18px;

    .filetype-text {
      position: absolute;
      bottom: 15px;
      left: 14px;
      font-weight: bold;
      color: white;
      font-size: 14px;
      width: 28px;
      text-align: center;
      letter-spacing: 1px;

      &.json {
        font-size: 12px;
        left: 12.5px;
      }
    }

    .right-section {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .file-title {
      font-weight: bold;
      letter-spacing: 0.5px;
      color: #717171;
      margin-bottom: 3px;
    }

    .file-metadata {
      color: #ABABAB;
    }

    .dot-seperator {
      opacity: 0.8;
      font-size: 12px;
      margin-left: 8px;
      margin-right: 8px;
      line-height: 12px;
    }
  }

  .link-to-notion-tutorial {
    color: #5A74FF;
    text-decoration: underline;
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 40px;
    cursor: pointer;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .file-title-heading {
    font-size: 22px;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 18px;
    color: #969696;
  }

  .file-quantity {
    font-weight: 100;
    font-size: 16px;
    opacity: 0.8;
  }

}


.export-container {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    position: absolute;
    top: -18px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }

  .loading-text-pageload {

    .lds-ellipsis-2 {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }

    .lds-ellipsis-2 div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #3D3E6C;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis-2 div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis-2 div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis-2 div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis-2 div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }

    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }

      100% {
        transform: scale(0);
      }
    }

    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }

      100% {
        transform: translate(24px, 0);
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .profile-container .left-side {
    display: none;
  }

  .info-alert {
    width: 90vw !important;
    margin: 0 auto !important;
    margin-top: 40px !important;
  }

  h1.title {
    font-size: 34px !important;
  }

  p.subtitle {
    font-size: 22px !important;
    line-height: 28px !important;
  }
}

@media screen and (max-width: 400px) {
  .export-container {
    .left-side {
      max-width: 100px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .export-container {
    .success-container-regular {
      display: none;
    }

    .success-container-too-small-device {
      display: inline !important;
    }
  }

}

@media screen and (max-width: 600px) {
  .export-container {
    .status-box-container {
      width: 100% !important;
      padding: 30px 40px;

      .name {
        font-size: 17px;
      }

      .value {
        font-size: 16px;
      }
    }

    .info-alert {
      font-size: 1rem !important;
    }

    .dot-seperator,
    .data-metadata,
    .title-filetype {
      display: none;
    }

    .left-side-of-right-section {
      max-width: 50vw !important;
    }

    .main-content-container {
      padding: 0 !important;
    }

    .file-icon-container {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }

    .file-title-heading {
      margin-left: 20px !important;
    }
  }
}


.bookmark-url {
  font-size: 16px;
  font-weight: lighter;
  opacity: 0.7;
}

.check-back-in-date {
  font-weight: bold;
}