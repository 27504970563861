section.jump-success {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url("../../../../img/map_bg.jpg") no-repeat;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  will-change: transform;
  max-width: 450px;
  margin: 0 auto;

  .subtitle {
    margin-top: 20px;
  }
  .center-vertically {
    align-items: center;
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .emoticon {
    font-size: 3.1rem;
    margin-bottom: 10px;
  }

  h1 {
    font-family: "Recoleta";
    font-size: 3.1rem;
    color: #393939;
    text-align: center;
    max-width: 14rem;
    line-height: 1.1;
    margin: 0 auto;
    letter-spacing: -0.5px;
    line-height: 3.4rem;
    margin-left: 20px;
    margin-right: 20px;
  }

  h6 {
    font-family: "SF Compact Display Light";
    font-size: 1rem;
    line-height: 1.6rem;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    color: #393939;
  }

  footer {
    margin-top: auto;
    width: 100%;
    padding-top: 47%;
    background: url("../../../../img/landing_footer.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    transform: scale(1.01);

    p {
      font-family: "SF Compact Display Bold";
      font-size: 0.95rem;
      text-align: center;
      color: #f9f5eb;
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
