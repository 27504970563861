.jump-list-screen-map {
  #map {
    // height: 6.5rem;
    height: 35vh;
    max-height: 390px;
    margin: 0;
    background: url(https://user-images.githubusercontent.com/44790691/158486424-95e7ef48-d901-4b42-8b91-30f17dd5c989.png);
  }

  .mk-map-view {
    // border-radius: 14px;
  }
}
