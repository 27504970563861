.themes {
  background-color: white;
  color: $smokii;
  min-height: 100vh;
  cursor: pointer;

  .theme-container {
    width: 100%;
    max-width: 450px;
    height: 200px;
    margin: 20px;

    img.theme-background {
      max-height: 120px;
      position: absolute;
      bottom: 0;
    }
  }
}
