.placelist-component {
  padding-left: var(--margin-left-menu);
  padding-right: var(--margin-right-menu);
  background-color: var(--secondary-color);
}

.place-item {
  box-shadow: var(--light-box-shadow);
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  background-color: var(--primary-color-20-opacity);
  color: var(--primary-color);
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .thumbnail-container {
    width: 70px;
    height: 70px;
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }
  .place-name {
    font-size: 18px;
    font-family: var(--main-font);
    padding-top: 12px;
    padding-left: 9px;
    padding-right: 40px;
  }
  .place-address {
    font-size: 13px;
    font-family: var(--main-font-light);
    padding-top: 5px;
    padding-left: 9px;
    padding-right: 40px;
  }
}

// left bottom of the card
.pin-icon {
  position: absolute;
  bottom: 15px;
  right: 5px;
  &.apply-theme-coloring-primary {
    path {
      fill: var(--primary-color);
    }
    path:first-child {
      fill: var(--secondary-color);
    }
  }
  &.apply-theme-coloring-secondary {
    path {
      fill: var(--secondary-color);
    }
    path:first-child {
      fill: var(--secondary-color);
      fill: var(--primary-color);
    }
  }
}

// make card bigger on hover
// only on desktop, not on table and not on phone
.desktop {
  .place-item:hover {
    box-shadow: var(--light-box-shadow-hover);
    font-size: 20px;
    transform: scale(1.03);
  }
  .place-item  { // > svg
    transition: transform 0.07s ease-in;
  }
}
