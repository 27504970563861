.list--menu {
  // background-color: var(--secondary-color);
  .container {
    // position: relative; // to position the footer
    height: 100%;
    max-height: 100vh;
  }

  .avatar-section {
    // background-color: var(--primary-color);
    padding-bottom: 37px;
    // padding-left: var(--margin-left-menu);
    // padding-right: var(--margin-right-menu);
  }
  .banner {
    height: 220px;
    position: relative;
    .banner-img {
      width: 414px;
      position: absolute;
      bottom: 0;
    }
  }
}

.list--menu.desktop {
  width: 100%;
  max-width: calc(min(414px, 100vw));
  height: 100%;
}

// TABLET ADJUSTMENT
// account 15vh for the empty space at the top
.list--menu.tablet .container {
  max-height: 85vh;
}

h1.list-title {
  // background-color: var(--primary-color);
  color: var(--secondary-color);
  padding-right: var(--margin-right-menu);
  padding-left: var(--margin-left-menu);
  text-align: center;
  font-family: var(--header-font);
  font-weight: bold;
  font-size: 39px;
  padding-bottom: 25px;
  padding-top: 60px;
}
p.list-description {
  text-align: center;
  font-size: 15px;
  font-family: var(--main-font-light);
  color: var(--secondary-color);
  margin-bottom: 16px;
}
img.avatar {
  width: 45px;
  height: 45px;
  border: 2.5px solid white;
  border-radius: 50%;
  z-index: 1;
}
.header-sub-avatar {
  position: absolute;
  top: 190px;
  min-width: 190px;
  height: 36px;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  p {
    text-align: center;
    font-size: 13px;
    font-family: var(--main-font);
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 16px;
    margin: 0;
    margin-left: 2px;
    white-space: nowrap;
  }

  .spots-section {
    margin-left: 8px;
  }
  .author-section {
    margin-left: 10px;
    margin-right: 8px;
  }
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--secondary-color);
  a.footer-link {
    p.powered-by-text {
      font-size: 12px;
      color: var(--primary-color);
      margin-right: 5px;
    }
    img.liist-logo {
      width: 32px;
      margin-left: 10px;
    }
  }
}
.list--menu.mobile,
.list--menu.tablet {
  .footer {
    padding-bottom: 100px !important;
  }
}

// custom beautiful minimalistic scrollbar
// => https://codepen.io/xtrp/pen/QWjREeo
.scrollbar-container {
  height: 100%;
  padding: 0;
  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color-20-opacity); // color of the scrollbar
    // background-color: var(--primary-color) !important;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: var(
      --secondary-color-40-opacity
    ); // cover of scrollbar when on hover
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --secondary-color-60-opacity
    ); // cover of scrollbar when on hover
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
// SHOW SCROLLBAR ONLY ON :hover
// => https://stackoverflow.com/a/10015883/6272061
// div.show-scrollbar-on-hover-only:hover {
//   &::-webkit-scrollbar-thumb {
//     background-color: #d6dee1;
//   }
// }
// div.show-scrollbar-on-hover-only {
//   &::-webkit-scrollbar-thumb:hover {
//     background-color: #a8bbbf;
//   }
// }
// div.show-scrollbar-on-hover-only p {
//   // padding-right: 16px;
// }
// div.show-scrollbar-on-hover-only:hover p {
//   // padding-right: 0px;
// }
.action-bttn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  background-color: var(--secondary-color);
  .action-bttn {
    position: relative;
    background-color: var(--primary-color) !important;
    color: var(--secondary-color) !important;
    width: 100%;
    border-radius: 18px;
    padding: 16px 24px;
    font-size: 18px;
  }
  .icon-container {
    position: absolute;
    left: 18px;
    top: 14px;
  }
}
.action-bttns-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  background-color: var(--secondary-color);
  .action-bttn {
    border: var(--light-border);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color-20-opacity);
    color: var(--primary-color);
    width: 48%;
    padding: 8px;
    padding-top: 17px;
    padding-bottom: 15px;
    border-radius: 18px;
    &:hover {
      box-shadow: var(--light-box-shadow);
    }
  }
  .action-bttn-text {
    font-size: 14px;
    margin-top: 4px;
    color: var(--primary-color) !important;
  }
}

#copy-to-clipboard-hidden-input {
  position: absolute;
  z-index: -1000;
  top: 20px;
  margin-left: 10px;
  width: 170px;
  opacity: 0;
}
