// LIIST BRAND FONTS
// - Recoleta Bold (titles) ✅
// - Integral CF (titles) ✅
// - SF Compact Display Regular ✅
// - SF Compact Display Light ✅


// taken from this guide
// => https://css-tricks.com/snippets/css/using-font-face/
// DRAFT:
// @font-face {
//   font-family: 'MyWebFont';
//   src: url('webfont.eot'); /* IE9 Compat Modes */
//   src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
//        url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
//        url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
//        url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
// }
@font-face {
  font-family: 'Recoleta';
  src: url('../../fonts/Recoleta-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Recoleta-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Recoleta-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Recoleta-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/Recoleta-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/Recoleta-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'IntegralCF';
  src: url('../../fonts/IntegralCF-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/IntegralCF-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/IntegralCF-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/IntegralCF-Bold.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'SF Compact Display Bold';
  src: url('../../fonts/SF-Compact-Display-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/SF-Compact-Display-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url("../../fonts/SF-Compact-Display-Bold.otf") format("opentype"),
}
// @font-face {
//   font-family: 'SF Compact Display Medium';
//   src: url('../../fonts/SF-Compact-Display-Medium.woff') format('woff'), /* Pretty Modern Browsers */
//   url('../../fonts/SF-Compact-Display-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
//   url("../../fonts/SF-Compact-Display-Medium.otf") format("opentype"),
// }
@font-face {
  font-family: 'SF Compact Display Regular';
  src: url('../../fonts/SF-Compact-Display-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../../fonts/SF-Compact-Display-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url("../../fonts/SF-Compact-Display-Regular.otf") format("opentype"),
}
// @font-face {
//   font-family: 'SF Compact Display Thin';
//   src: url("../../fonts/SF-Compact-Display-Thin.otf") format("opentype"),
// }
@font-face {
  font-family: 'SF Compact Display Light';
  src: url("../../fonts/SF-Compact-Display-Light.otf") format("opentype"),
}
// @font-face {
//   font-family: 'SF Compact Display Ultralight';
//   src: url("../../fonts/SF-Compact-Display-Ultralight.otf") format("opentype"),
// }
:root {
  --header-font: "SF Compact Display Bold", -apple-system, Verdana, sans-serif;
  --main-font: "SF Compact Display Regular", -apple-system, Verdana, sans-serif;
  --main-font-light: "SF Compact Display Light", -apple-system, Verdana, sans-serif;
}
