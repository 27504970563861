.search-component {
  padding-top: 14px;
  padding-left: var(--margin-left-menu);
  padding-right: var(--margin-right-menu);
  padding-bottom: 14px;
  background-color: var(--secondary-color);
}
input.search-input {
  padding: 8px 20px;
  padding-left: 33px;
  border-radius: 12px;
  width: 100%;
  font-size: 17px;
  background-color: var(--primary-color-20-opacity);
  color: var(--primary-color);
  font-family: var(--main-font-light);
  &::placeholder {
    color: var(--primary-color-60-opacity);
    // color: var(--primary-color-20-opacity);
  }
}
.search-icon-container {
  top: 4px;
  left: 4px;
}
.close-icon-container {
  top: 4px;
  right: 4px;
  cursor: pointer;
}
.mobile .search-icon-container {
  top: 5px;
}
