.loader-wrapper {
  position: fixed;
  top: 0;
  opacity: 0.9;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d3e6c;
  flex-direction: column;
  &.ok {
    display: none;
  }
  &.error {
    opacity: 1;
  }
  svg.liist-logo {
    width: 100px;
    height: 50px;
  }
}

/**
 * ERROR CASE
 * => display Error message
 * => opacity 100%
 * =>
 */
img.pisa-tower {
  width: 250px;
  max-width: 100vw;
}
.something-wrong-text {
  margin-top: 45px;
  font-family: var(--header-font);
  color: $goldii;
  text-align: center;
}
.error-action-bttn {
  padding: 10px 30px;
  background-color: $goldii;
  color: $smokii-light;
  font-family: var(--header-font);
  font-size: 24px;
  font-weight: light;
  border-radius: 40px;
  margin-top: 47px;
  cursor: pointer;
}

/**
 * LOADING SPINNER TEMPLATE
 */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $goldii;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis.tiktokjump div {
  background: $viiolet;
}
.lds-ellipsis.tiktokjump.white {
  transform: scale(0.6);
}
.lds-ellipsis.tiktokjump.white div {
  background: white;
}
.lds-ellipsis.tiktokjump.place-details div {
  background: var(--theme-primary-color);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
