.jump-place-details-screen {
  z-index: 1000;
  background-color: #f9f5eb;
  width: 100vw;
  height: 100vh;
  max-width: 450px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.4s ease-in-out;

  .full-screen-container {
    height: 100vh;
    width: 100%;
    max-width: 450px;
    overflow: auto;
  }

  .carousel-container {
    height: 200px;
    height: 50%;
    max-height: 390px;
    width: 100%;
    background-color: $goldii;
    position: relative;

    .grey-overlay-area {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 3.75rem;
      &.top {
        left: 0;
        top: 0;
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 100%
        );
      }
      &.bottom {
        left: 0;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
      }
    }

    .back-bttn {
      z-index: 100;
      position: absolute;
      top: 0;
      left: 0.5rem;
      background: none;
      border: none;
      color: $creamii;
      font-size: 0.95rem;
      padding: 1.5rem 0.5rem;
      font-family: "SF Compact Display Regular";
      i {
        border: solid $creamii;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(135deg);
      }
    }

    .placeholder-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img.carousel-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .loading-container {
    width: 100%;
  }

  h2 {
    font-family: "Recoleta";
    font-size: 1.6rem;
    line-height: 1.5;
    color: $smokii;
  }

  .location-info {
    padding: 0.6rem 1.1rem;
    color: var(--theme-primary-color);
    font-family: "SF Compact Display Regular";
    font-size: 1.05rem;
  }
  .location-meta {
    display: flex;
    justify-content: space-between;
  }
  .dollar-sign-inactive {
    opacity: 0.3;
  }
  .location-reviews-container {
    padding: 0 1.1rem 1.5rem;
    display: flex;
    .review-bubble {
      width: 140px;
      height: 30px;
      margin-right: 1rem;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 40px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      color: $smokii;
      font-size: 0.75rem;
      img {
        height: 18px;
        margin-right: 5px;
        margin-left: 2px;
      }
      .star-rating {
        margin: 2px 0 0 5px;
      }
    }
  }
  .location-address {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 140px;
    padding-left: 22px;
    padding-right: 22px;
    svg {
      margin-right: 0.5rem;
    }
  }
  .action-bttn {
    position: fixed;
    bottom: 2.6rem;
    width: calc(100% - 52px);
    left: 26px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    border: none;
    border-radius: 14px;
    font-family: "SF Compact Display Bold";
    font-size: 1.4rem;
    &.remove {
      display: none;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 1rem;

      path {
        fill: #55072c;
      }
    }

    &.delete-location-bttn {
      background: #fe2c55;
      color: white;
      position: fixed;
      width: 90%;
      margin: 0 auto;
      z-index: 1;
      transform: translateX(-50%);
      left: 50%;
      max-width: 400px;
      bottom: 35px;
    }
    &.add-location-bttn {
      background: #ddedff;
      color: #55072c;
      position: fixed;
      width: 90%;
      margin: 0 auto;
      z-index: 1;
      transform: translateX(-50%);
      left: 50%;
      max-width: 400px;
      bottom: 35px;
    }
  }
}
