.picture-carousel {
  height: 400px;
  margin: 0 auto;
  // border-radius: 12px;
  overflow: hidden;
  .image-div {
    width: 200px;
    height: 200px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: rgba(0,0,0,0.07);
    object-fit: cover;
  }
}
