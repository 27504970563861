@import "./list/drawer.scss";
@import "./list/placeDetailsDrawer.scss";
@import "./list/androidSignupModal.scss";
@import "./list/floatingUiElements.scss";
@import "./list/map.scss";
@import "./list/menu.scss";
@import "./list/search.scss";
@import "./list/placeList.scss";

// add desktop styling
.list.desktop {
  display: grid;
  grid-template-columns: 414px auto;
}
// add mobile styling
.list.mobile, .list.tablet {
  position: relative;
  overflow: hidden;
}

// override carousel buttons
.react-multi-carousel-dot--active button {
  background: rgb(249,245,235) !important;
}
