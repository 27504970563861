.place-not-found-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 30px;
  text-align: center;
  font-family: "Recoleta";
  color: var(--theme-primary-color);
  font-size: 21px;

  .back-bttn {
    margin: 0 auto;
    margin-top: 40px;
    color: var(--theme-secondary-color);
    background: var(--theme-primary-color);
    max-width: 180px;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
  }
}

// .tiktok-jump-load
.try-again-bttn {
  margin: 0 auto;
  margin-top: 40px;
  color: var(--liist-creamii);
  background: var(--liist-smokii);
  max-width: 180px;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 23px;
  cursor: pointer;
}
.something-went-wrong-text {
  text-align: center;
  font-size: 2rem;
  padding-left: 30px;
  padding-right: 30px;
}
.customize-your-title {
  font-size: 24px !important;
  letter-spacing: 1.5px;
}
