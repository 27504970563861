.android-signup-modal {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: transparent;
  opacity: 1;
  backdrop-filter: blur(12px);

  .modal {
    box-shadow: 0 0 24px rgba(0,0,0,0.05);
    padding-top: 44px;
    padding-bottom: 44px;
    width: 342px;
    height: 471px;
    background-color: $creamii;
    border-radius: 20px;
    img.android-robot {
      width: 171px;
      height: 171px;
      margin-bottom: 20px;
    }
    p.android-message-text {
      font-family: var(--main-font);
      font-size: 24px;
      text-align: center;
      padding-left: 44px;
      padding-right: 44px;
      margin-bottom: 35px;
    }
    .android-bttn {
      width:85%;
      height:55px;
      background-color: white;
      color: $viiolet;
      text-align: center;
      line-height: 55px;
      margin: 0 auto;
      margin-top: 11px;
      border-radius: 14px;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      &.main {
        background-color: $viiolet;
        color: white;
      }
    }
  }
}
