.jump-list-screen-bttns {
  .action-bttns-wrapper {
    height: 220px;
    background: rgb(249, 245, 235);
    background: linear-gradient(
      0deg,
      rgba(249, 245, 235, 1) 75%,
      rgba(249, 245, 235, 0) 100%
    );
  }
  .action-bttn {
    position: absolute;
    bottom: 2.6rem;
    width: calc(100% - 43px);
    left: 26px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    border: none;
    border-radius: 14px;
    font-family: "SF Compact Display Bold";
    font-size: 1.4rem;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 1rem;
      path {
        fill: #55072c;
      }
    }
    &.done-bttn {
      background: #6e6dff;
      color: white;
      margin: 0 auto;
      z-index: 1;
      transform: translateX(-50%);
      left: 50%;
      max-width: 400px;
      bottom: 35px;
      // top: calc(100vh - 93px);
    }
    &.add-another-location-bttn {
      background: #ddedff;
      color: #55072c;
      margin: 0 auto;
      z-index: 1;
      transform: translateX(-50%);
      left: 50%;
      max-width: 400px;
      bottom: 117px;
      // top: calc(100vh - 175px);
    }
  }
}
