.jump-list-screen.active,
.jump-place-details-screen.active {
  transform: translateX(-50%);
}
.jump-list-screen.left,
.jump-list-screen-bttns.left {
  transform: translateX(-125%);
}
.jump-list-screen.right,
.jump-place-details-screen.right {
  transform: translateX(50%);
}
.jump-list-screen-bttns {
  z-index: 1;
  background: transparent;
  position: absolute;
  width: 100vw;
  max-width: 450px;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.4s ease-in-out;
}
.jump-list-screen {
  background-color: $creamii;
  width: 100vw;
  height: 100vh;
  max-width: 450px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.4s ease-in-out;
  overflow: auto;

  .banner {
    height: 104px;
    width: 100%;
    background: url("https://app.liist.com/themes/date_ideas.png");
    background-color: #ddedff;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .spots-count-container {
      position: absolute;
      bottom: 0px;
      background-color: $creamii;
      padding: 6px 14px;
      min-width: 150px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        color: $smokii;
        font-family: "SF Compact Display Regular";
        font-size: 14px;
      }
    }
  }

  .added-locations-list {
    padding-bottom: 40px;
    padding-top: 25px;
    &.hidden {
      display: none;
    }
    &.extra-bottom-space {
      // for action buttons when permission=edit
      padding-bottom: 220px;
    }

    img {
      height: 40px;
    }
    .location-preview {
      height: 4rem;
      display: flex;
      margin: 0 22px 1.1rem;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);

      .location-preview-img {
        position: relative;
        overflow: hidden;
        flex: 1 0 4rem;
        img {
          object-fit: cover;
          min-width: 100%;
          min-height: 100%;
          max-width: 150%;
          max-height: 150%;
          width: 100%;
          height: 100%;
        }
      }
      .location-preview-content {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(100% - 4rem);
        padding: 0 0.7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #ddedff40;
        color: $smokii;
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.08);

        h4.text-elipsis,
        p.text-elipsis {
          max-width: 60vw; // for text-elipsis to work
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h4 {
          font-family: "SF Compact Display Bold";
          font-size: 0.96rem;
          line-height: 1.8;
        }

        p {
          font-family: "SF Compact Display Regular";
          font-size: 0.74rem;
          opacity: 0.6;
        }
      }
    }
  }

  // ADD LOCATION OVERLAY
  .add-location-overlay {
    width: 100vw;
    height: 100vh;
    background-color: $creamii;
    max-width: 450px;
    position: absolute;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out 0s;

    &.active {
      opacity: 1;
      visibility: visible;
    }
    .landing-screen {
      position: relative;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      background: url("../../../../img/map_bg.jpg") no-repeat;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      transition: -webkit-transform 0.4s ease-in-out;
      transition: transform 0.4s ease-in-out;
      transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
      will-change: transform;
      max-width: 450px;
      margin: 0 auto;

      h1 {
        font-family: "Recoleta";
        color: #393939;
        text-align: center;
        max-width: 28rem;
        line-height: 1.1;
        margin: 0 auto;
        letter-spacing: -0.5px;
        margin-bottom: 40px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20%;
        font-size: 3.1rem;
        line-height: 3.4rem;
      }

      footer {
        margin-top: auto;
        width: 100%;
        padding-top: 47%;
        background: url("../../../../img/landing_footer.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        transform: scale(1.01);

        p {
          font-family: "SF Compact Display Bold";
          font-size: 0.95rem;
          text-align: center;
          color: #f9f5eb;
          position: absolute;
          bottom: 20%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
