* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  // border-radius: 0 !important;
}

html {
  height: 100%;
}

body {
  color: $smokii;
  font-family: var(--main-font);
  // background-color: var(--primary-color);
}

// remove ios ugly default styling of safari
// => https://pupungbp.com/remove-default-button-and-form-styling-on-safari-ios/
input[type="text"],
input[type="button"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.main {
  .main-content {
    display: flex;
    flex-direction: column;
  }
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

// Basic Styling
.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.display-none {
  display: none;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.center-text {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

a.remove-all-link-styling,
a.remove-all-link-styling:visited,
a.remove-all-link-styling:hover,
a.remove-all-link-styling:active {
  color: inherit;
}

.remove-all-link-styling {
  outline: 0;
  text-decoration: none;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.height-100 {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.text-elipsis {
  // wrap text
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.transparent {
  opacity: 0;
}

.no-height {
  max-height: 0px;
}

.width-100 {
  width: 100%;
}

.width-auto {
  width: auto !important;
}

.height-auto {
  height: auto !important;
}

// remove selection and borders on click
// https://stackoverflow.com/a/21003770/6272061
.no-select {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.light-border {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

// ONLY FOR TESTING PURPOSES
button.devtest {
  margin: 20px;
  padding: 10px;
}

/**
 * DEFINE CSS VARIABLES
 * - fonts
 * - margin
 * - border
 */
:root {
  --primary-color: transparent;
  --primary-color-20-opacity: transparent;
  --secondary-color: transparent;
  --secondary-color-20-opacity: transparent;
  --secondary-color-40-opacity: transparent;
  --secondary-color-60-opacity: transparent;

  --margin-left-menu: 23px;
  --margin-right-menu: 23px;
  // --margin-left-menu: 27px;
  // --margin-right-menu: calc(var(--margin-left-menu) - 14px);
  --light-box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
  --light-box-shadow-hover: 0 0 11px rgba(0, 0, 0, 0.11);
  --light-border: 1px solid rgba(0, 0, 0, 0.09);
  --ultra-light-border: 1px solid rgba(0, 0, 0, 0.05);
}

.light-border {
  border: var(--light-border);
}

.margin-left-menu {
  margin-left: var(--margin-left-menu);
}

.margin-right-menu {
  margin-right: var(--margin-right-menu);
}

.padding-left-menu {
  padding-left: var(--margin-left-menu);
}

.padding-right-menu {
  padding-right: var(--margin-right-menu);
}

.padding-left-menu {
  padding-left: var(--margin-left-menu);
}

.padding-right-menu {
  padding-right: var(--margin-right-menu);
}

.light-box-shadow {
  box-shadow: var(--light-box-shadow);
}

.light-box-shadow-hover {
  box-shadow: var(--light-box-shadow-hover);
}

.margin-left-10 {
  margin-left: 10px;
}

.unset {
  all: unset;
}

.flex-grow {
  flex-grow: 1;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.right-align {
  text-align: right;
}