
.list--drawer {
  height: 100%;
  overflow: hidden;
  background-color: var(--primary-color);
}

.list--drawer.mobile, .list--drawer.tablet {
  z-index: 10;
  position: absolute;
  width: 414px;
  max-width: 100vw;
  min-height: 100vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  overflow: hidden;
  transition: all 0.4s ease;
  &.closed {
    top: calc(100vh);
  }
  &.open {
    top: 0px;
  }
  // handler only availible on mobile and tablet
  .drawer-handle-container {
    position: relative;
    .drawer-handle-clickable-area {
      position: absolute;
      width: 414px;
      max-width: 100vw;
      height: 50px;
      top: 0;
      background-image: linear-gradient(to top, rgba(249, 245, 235, 0), var(--secondary-color-60-opacity));
      z-index: 10;
      transition: all 0.2s ease;
      opacity: 0;
      &.show {
        opacity: 1;
      }
    }
  }
}
// leave space when drawer is closed on tablet
.list--drawer.tablet {
  box-shadow: 0 0 20px rgba(0,0,0,0.12);
  &.open {
    top: 15vh !important; // important just to be safe in case code gets moved
  }
}

// CHANGES OF OPENED DRAWER ON MOBILE:
// (1) handle invisible
// (2) no rounded borders

// (1) make handle invisible when drawer is opened on mobile
.list--drawer.mobile.open .drawer-handle {
  opacity: 0;
}
.list--drawer.mobile.closed .drawer-handle {
  opacity: 1;
}
// (2) no border radius when drawer is op
.list--drawer.mobile.open {
  border-radius: 0;
}

// for phones that are bigger than standard 414px
@media only screen and (max-width: 450px) {
  .list--drawer {
    width: 450px !important;
  }
}
