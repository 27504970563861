.jump-place-details-screen-map-preview {
  #map-preview {
    height: 6.5rem;
    margin: 0 17px;
    margin-bottom: 1.4rem;
    // background: #d2efff;
    // background: url(https://user-images.githubusercontent.com/44790691/158486424-95e7ef48-d901-4b42-8b91-30f17dd5c989.png);
    border-radius: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .mk-map-view {
    border-radius: 14px;
  }
}
