.jump-list-screen-search {
  .input-section {
    position: relative;
    height: 54px;
    margin: 2rem 25px 1rem;
    svg {
      position: absolute;
      left: 20px;
      top: 20px;
    }
    input {
      width: 100%;
      height: 100%;
      background: #fff;
      border: none;
      border-radius: 14px;
      padding: 0 40px 0 50px;
      font-family: "SF Compact Display Regular";
      font-size: 14px;
      color: #2c2c2c;
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.08);
    }
    button.location-clear-btn {
      height: 100%;
      width: 50px;
      right: 0;
      background: none;
      border: none;
      outline: none !important;
      position: absolute;
    }
  }

  .results-section {
    margin-bottom: 25px;
    margin-left: 30px;
    margin-right: 30px;
    .location-option {
      height: 4rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(44, 44, 44, 0.3);
      font-family: "SF Compact Display Regular";
      font-size: 0.95rem;
      color: #2c2c2c;
      flex-direction: column;
      color: #000;
      line-height: 1.4;
      align-items: flex-start;
      justify-content: center;

      svg {
        margin-right: 5px;
      }

      h5 {
        font-family: "SF Compact Display Bold";
        font-size: 0.9rem;
      }

      p {
        font-family: "SF Compact Display Regular";
        font-size: 0.75rem;
      }
    }
  }
}
